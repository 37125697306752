<template>
  <div
    class="w-full min-w-full min-h-screen md:grid md:grid-cols-12 site-background-gradient"
  >
    <div class="col-span-12 sticky top-0 z-50 w-full max-h-fit">
      <div class="md:grid md:grid-cols-12 bg-white dark:bg-slate-800">
        <div class="md:col-span-1"></div>
        <div class="md:col-span-10">
          <LayoutMenu class="h-[50px] max-h-[50px] min-h-[50px]"></LayoutMenu>
        </div>
        <div class="md:col-span-1"></div>
      </div>
    </div>
    <div class="md:col-span-1"></div>
    <div class="md:col-span-10">
      <NuxtPage
        class="h-auto z-40 py-1 px-2 md:px-2 md:py-1 md:min-h-96"
      ></NuxtPage>
    </div>
    <div class="md:col-span-1"></div>
    <div class="col-span-12 z-30 w-full">
      <div class="md:grid md:grid-cols-12">
        <div class="md:col-span-1"></div>
        <div class="md:col-span-10">
          <LayoutFooter></LayoutFooter>
        </div>
        <div class="md:col-span-1"></div>
      </div>
    </div>
    <CookieBanner></CookieBanner>
  </div>
</template>

<style scoped>
.site-background-gradient {
  @apply h-full bg-gradient-to-br from-white to-blue-50 dark:from-slate-950 dark:to-slate-900;
}
</style>
